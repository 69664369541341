import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from 'pages/home'
import WebsiteTerms from 'pages/website-terms'
import WebsitePrivacy from 'pages/website-privacy'
import ApplicationPrivacy from 'pages/application-privacy'
import ApplicationTerms from 'pages/application-terms'

const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/terms' element={<WebsiteTerms />} />
        <Route path='/application-terms' element={<ApplicationTerms />} />
        <Route path='/privacy' element={<WebsitePrivacy />} />
        <Route path='/application-privacy' element={<ApplicationPrivacy />} />
      </Routes>
    </Router>
  )
}

export default Pages
