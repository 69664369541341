import { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from 'components/Layout'

const WebsitePrivacy = () => {

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <section className='terms w-full px-6 sm:px-16  antialiased mb-20 text-sm'>
        <div className='content px-2 sm:px-16'>
          <Link to='/' className='flex hover:text-white'>
            <svg
              viewBox='0 0 33 22'
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 mr-4 text-white'
              fill='currentColor'
              stroke='none'
            >
              <path d='M12 1.4375C12.3125 1.75 12.5 2.125 12.5 2.5C12.5 2.9375 12.3125 3.3125 12 3.5625L6.0625 9.5H31.5C32.3125 9.5 33 10.1875 33 11C33 11.875 32.3125 12.5 31.5 12.5H6.0625L12 18.5C12.625 19.0625 12.625 20 12 20.5625C11.4375 21.1875 10.5 21.1875 9.9375 20.5625L1.4375 12.0625C0.8125 11.5 0.8125 10.5625 1.4375 10L9.9375 1.5C10.5 0.875 11.4375 0.875 12 1.4375Z' />
            </svg>
            <span className='text-sm'>Back</span>
          </Link>

          <h1 className='text-2xl font-bold py-6'>Keet.io Privacy Statement</h1>

          <p>Last updated <strong>February 11, 2025</strong></p>

          <p>Thank you for visiting keet.io (the “<strong>Site</strong>”). The Site is operated by Holepunch, S.A. de C.V. (“Holepunch”, “<strong>we</strong>”, “<strong>our</strong>”, or “<strong>us</strong>”).</p>

          <p>This privacy notice (“<strong>Privacy Statement</strong>”) sets out the basis on which any Personal Information we collect from you (“<strong>you</strong>”, “<strong>your</strong>”, or “<strong>customer</strong>”) or that you provide to us, will be processed by us. By “Personal Information”, we mean any information which, either alone or in combination with other data, enables you to be directly or indirectly identified, for example your name, email address, username, contact details or any unique identifier such as an IP address, device ID or other online identifier.</p>

          <p>Capitalized terms that are not defined in this Privacy Statement shall have the meaning ascribed in our <Link to='/terms'>Website Terms</Link> (the “<strong>Terms</strong>”).</p>

          <p>Please read the following carefully to understand what Personal Information we collect, how that Personal Information is used and the ways it can be shared by us.</p>
          
          <p>The data controller of your Personal Information is Holepunch, S.A. de C.V., an El Salvador Sociedad Anónima de Capital Variable with its registered office at Colonia Escalón, 87 Avenida Norte, Calle el Mirador, Edificio Torre Futura, Oficina 06, Nivel 11, San Salvador, El Salvado. Its EU representative is: Rickert Rechtsanwaltsgesellschaft mbH, whose registered office is Colmantstraße 15, 53115, Bonn, Germany. Its UK representative is: Rickert Services Ltd UK whose registered office is PO Box 1487 Peterborough PE1 9XX United Kingdom.</p>

		      <h3>1. Our Processing of Your Personal Information</h3>

          <table className='border-collapse border'>
            <tbody> 
              <tr> 
                <td className='border p-3'> 
                  Purpose
                </td>
                <td className='border p-3' colspan='2'> 
                  Personal Information
                </td>
                <td className='border p-3'> 
                  Legal basis
                </td>
              </tr>
              <tr> 
                <td/>
                <td className='border p-3'> 
                  From You
                </td>
                <td className='border p-3'> 
                  From Others and Your Devices
                </td>
                <td/>
              </tr>
              <tr> 
                <td className='border p-3'> 
                  <p>Administer the website, system administration and detecting usage patterns (including broad demographic information for aggregated use), including developing and enhancing the Site</p>
                </td>
                <td className='border p-3'> 
                  <p>N/A</p>
                </td>
                <td className='border p-3'> 
                  <p>IP address, browser type, access times, URL requested and referral URL</p>
                </td>
                <td className='border p-3' rowspan="2"> 
                  <p>We think we can<br/>(legitimate interest) 
                  </p>
                </td>
              </tr>
              <tr> 
                <td className='border p-3'> 
                  <p>Detection for troubleshooting purposes</p>
                </td>
                <td className='border p-3'> 
                  <p>N/A</p>
                </td>
                <td className='border p-3'> 
                  <p>Technical information, including the Internet protocol (IP) address used to connect your computer or other device to the Internet, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform</p>
                  <p>Information about your visit, including the dates and times you use the Site, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call the contact phone number provided on our Site</p>
                </td>
              </tr>
              <tr> 
                <td className='border p-3'> 
                  <p>Reply to your e-mail communications</p>
                </td>
                <td className='border p-3'> 
                  <p>Email address (and other personal data you disclose in your communication with us)</p>
                </td>
                <td className='border p-3'> 
                  <p>N/A</p>
                </td>
                <td className='border p-3'> 
                  <p>You agreed to it<br/>(consent)</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p>When we say that we think we can process your Personal Information, it is because we considered we have a legitimate interest (for ourselves or of a third party) to do so; it is in the interest of our organization to conduct and manage our business; this includes conducting analytics to improve and optimize the Site and the services, protecting our interests and enforcing agreements with others, as well as complying with industry self-regulatory requirements. We might have legitimate interest to process Personal Information in other contexts. We ensure that we balance any potential impact on you and your rights before we process your Personal Information on that basis. You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us. Please also consult the section “Your rights” below.</p>

          <p>It is important that the Personal Information we collect from you is accurate and current. Please keep us informed if your Personal Information changes during your relationship with us. You can do so by lodging a customer support ticket. We will endeavor to effect those changes within a reasonable timeframe.</p>

          <p>If you do not wish for your Personal Information to be used in the ways described within this Privacy Statement then you should not use the Site or use the services, functions, or features offered from time to time on the Site (“<strong>Services</strong>”). Where we need information from you to offer our Services or meet any of our legal or regulatory requirements, failure to provide such information will mean that you will not get access to the Services.</p>

          <p>We do not rely on consent as a legal basis for processing your Personal Information other than and when we reply to your communications via email. From time to time, we may share your Personal Information with third parties with your consent.</p>
          
          <p>Aggregated Data. We may also aggregate personal and other data captured by us so that the data is no longer capable of identifying an individual. Aggregated data may cover patterns of usage or information and data that you provide to us, and we reserve the right to use this aggregated information for the purposes of improving and enhancing our Services, generating insights, for use in marketing to other users and current and potential partners and otherwise for the purposes of our business. Provided that such aggregated data does not directly or indirectly identify you as an individual, this data is not considered to be Personal Information and is not covered by this Privacy Statement.</p>

		      <h3>2. Security and Confidentiality</h3>

          <p>We are committed to protecting your privacy. Internally, only people with a business need to know Personal Information, or whose duties reasonably require access to it, are granted access to customers’ Personal Information. Such individuals will only process your Personal Information on our instructions and are subject to a duty of confidentiality. We audit our personal compliance regularly.</p>

          <p>The Site’s systems and data are reviewed periodically to ensure that you are getting a quality service and that leading security features are in place. We have put in place procedures to deal with any actual or suspected data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

          <p>We take all reasonable endeavors to protect and safeguard Personal Information. Unfortunately, the transmission of information via the Internet is not completely secure. While we do our utmost to protect your Personal Information, we cannot guarantee the security of your data transmitted to us over email or through the Site; any transmission is at your own risk. Once we have received your Personal Information, we will use strict procedures and security features to try to prevent unauthorized access, but there are protective measures you should take, as well. Do not share your Personal Information with others unless you clearly understand the purpose of their request for it and you know with whom you are dealing. Do not keep sensitive Personal Information in your e-mail inbox or on Webmail. If you are asked to assign passwords to connect you to your Personal Information, you should use a secure password and always use two-factor authentication (2FA), where available. You should change your password regularly.</p>

          <h3>3. What and with whom we share your Personal Information</h3>

          <h4>Sharing with third parties</h4>

          <p>As necessary, we will share your Personal Information with:</p>

          <p>3.1. With any member of our group, which means our direct or indirect subsidiaries, our ultimate holding company and any other subsidiary of that holding company, including their respective shareholders, directors, officers, affiliates, employees, contractors, agents, partners, insurers and attorneys or representatives. </p>

          <p>3.2. With our service providers, to the extent necessary to supply the Services to you.</p>

          <p>3.3. With selected third parties, including analytics and search engine providers that assist us in the improvement and optimization of the Services.</p>

          <p>3.4. With third-Party Services integrated with our Site.</p>

          <p>3.5. With authorities and law enforcement agencies worldwide either when ordered to do so or on a voluntary basis if this appears reasonable and necessary to us.</p>

          <p>We may also disclose your Personal Information to third parties: </p>

          <p>3.6. If Holepunch or substantially all of its assets are acquired by a third party, in which case Personal Information held by it about its customers will be one of the transferred assets.</p>
          <p>3.7. If we are under a duty to disclose or share your Personal Information in order to comply with any legal obligation, or in order to enforce or apply our Terms of Service and other agreements; or to protect the rights, property, or safety of us, our clients, or others, including to defend ourselves from legal claims.</p>

          <h3>4. Transfers</h3>

          <p>Your Personal Information will be stored in the European Economic Area and may be transferred worldwide.</p>

          <p>Personal Information and other data may therefore be exported outside of the jurisdiction in which you reside. Your Personal Information may be processed and stored in a foreign country or countries. Under those circumstances, the governments, courts, law enforcement, or regulatory agencies of that country or those countries may be able to obtain access to your Personal Information through foreign laws. You need to be aware that the privacy standards of those countries may be lower than those of the jurisdiction in which you reside.</p>

          <p><u>EEA customers</u>. We will take all steps reasonably necessary to ensure that your Personal Information is treated securely and in accordance with this Privacy Statement. All data you provide to us is stored on our secure servers. Where we transfer our data outside of the EEA, we ensure that adequate safeguards are in place. That includes, where necessary, taking steps to evaluate the risks raised by the transfers in countries that do not offer an adequate level of protection. Please contact us if you want further information on the specific mechanism used by us when transferring your Personal Information out of the EEA.</p>

          <h3>5. Data Retention</h3>

          <p>We have data retention and deletion policies designed to retain Personal Information for no longer than necessary for the purposes set out herein or as otherwise required to meet legal or business needs. Because of those retention requirements, we might not be able to honor erasure requests.</p>
          
          <h3>6. Your rights</h3>

          <p><u>All customers.</u> You may access, verify, request the deletion of your Personal Information held by Holepunch and, where consent is the legal basis for processing of your Personal Information and subject to any legal or contractual restrictions, withdraw your consent, in each case, by a written request to Holepunch at hello@holepunch.to. Our electronic marketing communications also contain opt-out mechanism and options to update your contact information or change your preferences at any time. We will do our best to honor your request promptly. </p>

          <p><u>EEA customers.</u> You have a number of rights in relation to how we process your Personal Information. You may exercise these rights by contacting us (see details below). These include the right to:</p>

          <p>6.1. access the Personal Information that we may hold about you;</p>

          <p>6.2. rectify any inaccurate Personal Information that we may hold about you;</p>

          <p>6.3. have your Personal Information erased in certain circumstances, for example, where it is no longer necessary for us to process your Personal Information to fulfill our processing purposes; or where you have exercised your right to object to the processing;</p>

          <p>6.4. restrict the processing of your Personal Information where, for example, the information is inaccurate or it is no longer necessary for us to process such information or where you have exercised your right to object to our processing;</p>

          <p>6.5. object to the processing of your Personal Information which may be exercised in certain circumstances, for example, where your own legitimate interests outweigh ours;</p>

          <p>6.6. have your Personal Information ported to a new service provider if you no longer wish to use the Services. You may exercise these rights by contacting us (see details below);</p>

          <p>6.7. when we rely on your consent to process Personal Information (e-mail communications), you have the right to withdraw your consent at any time. For e-mail communications, you can do so by saying so in your email communication with us. We will do our best to honor your request promptly.</p>

          <h4>No fee usually required</h4>

          <p>You will not have to pay a fee to access your Personal Information or to exercise any of your other rights. We may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>

          <h4>What we may need from you</h4>

          <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Information (or to exercise any of your other rights). This is a security measure to ensure that Personal Information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

          <h4>Time limit to respond</h4>

          <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

          <h3>7. Changes to this Privacy Statement</h3>

          <p>Any changes we make to our Privacy Statement in the future will be posted on this page. Please check back frequently to see any updates or changes to our Privacy Statement.</p>

          <h3>8. Contact &amp; Complaints</h3>

          <p>Holepunch’s Data Protection Officer (“DPO”) is responsible for overseeing our privacy practices. If you have any questions or concerns about this Privacy Policy or our privacy practices, or wish to exercise your rights, please contact our DPO at hello@holepunch.to.</p>

          <p><u>EEA customers only</u> Should you have any concerns about how we handle your Personal Information, please contact us in the first instance by email at hello@holepunch.to. You can also contact our EU/UK representative art-27-rep-holepunch@rickert.law or art-27-rep-holepunch@rickert-services.uk. We will do our best to resolve your concern. You can also submit a complaint to the national supervisory authority within your jurisdiction, details of which can be found online.</p>
        </div>
      </section>
    </Layout>
  )
}

export default WebsitePrivacy
